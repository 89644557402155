import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload week starts today`}</em></p>
    <p>{`DB Deadlifts 12-12-12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`100ft DB Farmers Walk (60’s/45’s)`}</p>
    <p>{`15-KB SDHP’s (53/35)`}</p>
    <p>{`12-Wall Balls (20/14)`}</p>
    <p>{`9-Box Jumps (24/20)`}</p>
    <p>{`::: {.entry .clear}
`}<strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts August 26th.  Email Eric for more
details: Fallscitystrength\\@gmail.com`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Friendly Reminder.  As posted on our CrossFit the Ville Schedule
page since moving to our East Main location:`}</em></strong></p>
    <p><strong parentName="p">{`OPEN GYM HOURS`}</strong></p>
    <p><em parentName="p">{`(Open Gym when CrossFit classes aren’t in session)`}</em></p>
    <p><strong parentName="p">{`Monday — Friday`}</strong></p>
    <p>{`7am-12pm, 1pm-4:30pm, 6:30pm-8pm`}</p>
    <p><strong parentName="p">{`Saturday`}</strong></p>
    <p>{`10am-12pm`}</p>
    <p><strong parentName="p"><em parentName="strong">{` `}{`*`}{`*`}{`The only exception during class times are working in the open
gym area and on the weightlifting platforms.`}</em></strong>{`
:::`}</p>
    <p>{`::: {.post-footer}
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      